<template>
  <button
    :class="{
      [padding[size]]: true,
      [fontSize[size]]: true,
    }"
    class="bg-[#3344bc] text-[#e8b543] rounded-full"
  >
    <span class="leading-7">
      <slot />
    </span>
  </button>
</template>

<script setup lang="ts">
type Size = 'sm' | 'lg'

type Sizeble = {
  [key in Size]: string
}

const { size = 'sm' } = defineProps<{
  size?: Size
}>()

const padding: Sizeble = {
  sm: 'px-3 py-1',
  lg: 'px-5 py-2.5',
}

const fontSize: Sizeble = {
  sm: 'text-sm',
  lg: 'text-2xl',
}
</script>
